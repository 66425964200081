import React, {useState, useEffect} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import Config from "./../../util/config";
import {
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import {useSelector} from "react-redux";

import axios from "axios";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed, match}) => {

    let {navStyle, themeType} = useSelector(({settings}) => settings);
    let {pathname} = useSelector(({common}) => common);

    const getNoHeaderClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
            return "gx-no-header-notifications";
        }
        return "";
    };
    const getNavStyleSubMenuClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
            return "gx-no-header-submenu-popup";
        }
        return "";
    };
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    const [userMenu, setUserMenu] = useState([]);
    useEffect(() => {
        // console.log("Hello");
        getUserMenu();

    }, []);

    const getUserMenu = async () => {
        // const userData = localStorage.getItem("userData");
        // const dataparse = JSON.parse(userData);
        // const userId = dataparse.user_name || "";
        return await axios.get(Config.apiserver + "getusermenu/?username=superadmin")
            .then((response) => {
                console.log(response);
                if (response.status == 200) {
                    setUserMenu(response.data.allmenu);
                    // console.log(response.data.allmenu);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
            <div className="gx-sidebar-content" style={{background: '#34495e;'}}>
                <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
                    <UserProfile/>
                    {/*<AppsNavigation/>*/}
                </div>
                <CustomScrollbars className="gx-layout-sider-scrollbar">
                    <Menu
                        defaultOpenKeys={[defaultOpenKeys]}
                        selectedKeys={[selectedKeys]}
                        theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                        mode="inline">

                        <Menu.Item key="main/dashboard/farming">
                            <Link to="/main/dashboard/farming">
                                <i className="icon icon-dasbhoard"/>
                                <span><IntlMessages id="sidebar.dashboard"/></span>
                            </Link>
                        </Menu.Item>


                        {
                            userMenu.map((module, index) =>

                                <SubMenu key={index} popupClassName={getNavStyleSubMenuClass(navStyle)}
                                         title={<span> <i className={module.icon} style={{marginRight: "10px"}}/>
                                             <span><IntlMessages id={module.name}/></span></span>}>
                                    {
                                        module.submodule.map((submenu, index) => {
                                            if (submenu.menu.length === 0) {

                                                return <Menu.Item key={index}>

                                                    <Link to={"/" + module.urlkey + '/' + submenu.urlkey}
                                                          style={{marginLeft: "-20px"}}>
                                                        <i className={submenu.icon}/>
                                                        <span><IntlMessages id={submenu.name}/></span>
                                                    </Link>
                                                </Menu.Item>

                                            } else {
                                                return <SubMenu key={`submenu_` + index}
                                                                popupClassName={getNavStyleSubMenuClass(navStyle)}
                                                                title={<span>
                                                    <span><IntlMessages id={submenu.name}/></span></span>}>

                                                    {submenu.menu.map((menu, index) =>

                                                        <Menu.Item key={index}>

                                                            <Link to={"/" + module.urlkey + "/" + menu.urlkey}>
                                                                <span><IntlMessages id={menu.name}/></span>
                                                            </Link>
                                                        </Menu.Item>
                                                    )}
                                                </SubMenu>
                                            }

                                        })
                                    }

                                </SubMenu>
                            )
                        }

                        {/*<MenuItemGroup key="in-built-apps" className="gx-menu-group"*/}
                        {/*               title={<IntlMessages id="sidebar.inBuiltApp"/>}>*/}
                        {/*  <Menu.Item key="in-built-apps/mail">*/}
                        {/*    <Link to="/in-built-apps/mail"><i className="icon icon-email"/><span><IntlMessages*/}
                        {/*      id="sidebar.mailApp"/></span></Link>*/}
                        {/*  </Menu.Item>*/}

                        {/*  <Menu.Item key="in-built-apps/todo">*/}
                        {/*    <Link to="/in-built-apps/todo"><i*/}
                        {/*      className="icon icon-check-square-o"/><span><IntlMessages*/}
                        {/*      id="sidebar.todoApp"/></span></Link>*/}
                        {/*  </Menu.Item>*/}

                        {/*  <Menu.Item key="in-built-apps/contacts">*/}
                        {/*    <Link to="/in-built-apps/contacts"><i className="icon icon-contacts"/><span><IntlMessages*/}
                        {/*      id="sidebar.contactsApp"/></span></Link>*/}
                        {/*  </Menu.Item>*/}

                        {/*  <Menu.Item key="in-built-apps/chat">*/}
                        {/*    <Link to="/in-built-apps/chat"><i*/}
                        {/*      className="icon icon-chat-bubble -flex-column-reverse"/><span><IntlMessages*/}
                        {/*      id="sidebar.chatApp"/></span></Link>*/}
                        {/*  </Menu.Item>*/}

                        {/*  <Menu.Item key="main/notes">*/}
                        {/*    <Link to="/in-built-apps/notes"><i className="icon icon-copy"/>*/}
                        {/*      <span><IntlMessages id="sidebar.notes"/></span></Link>*/}
                        {/*  </Menu.Item>*/}


                        {/*  <Menu.Item key="in-built-apps/firebase-crud">*/}
                        {/*    <Link to="/in-built-apps/firebase-crud"><i*/}
                        {/*      className="icon icon-icon"/><span><IntlMessages*/}
                        {/*      id="sidebar.crud"/></span></Link>*/}
                        {/*  </Menu.Item>*/}
                        {/*</MenuItemGroup>*/}

                        {/*<MenuItemGroup key="social-apps" className="gx-menu-group" title={<IntlMessages id="sidebar.social"/>}>*/}
                        {/*  <Menu.Item key="social-apps/profile">*/}
                        {/*    <Link to="/social-apps/profile">*/}
                        {/*      <i className="icon icon-profile2"/>*/}
                        {/*      <span><IntlMessages id="sidebar.extensions.profile"/></span>*/}
                        {/*    </Link>*/}
                        {/*  </Menu.Item>*/}

                        {/*  <Menu.Item key="social-apps/wall">*/}
                        {/*    <Link to="/social-apps/wall">*/}
                        {/*      <i className="icon icon-avatar -flex-column-reverse"/>*/}
                        {/*      <span><IntlMessages id="sidebar.wall"/></span>*/}
                        {/*    </Link>*/}
                        {/*  </Menu.Item>*/}
                        {/*</MenuItemGroup>*/}

                        {/*<MenuItemGroup key="documents" className="gx-menu-group" title={<IntlMessages id="sidebar.documents"/>}>*/}

                        {/*  <Menu.Item key="documents/changelog">*/}
                        {/*    <Link to="/documents/changelog">*/}
                        {/*      <i className="icon icon-timeline-new"/>*/}
                        {/*      <span><IntlMessages id="sidebar.documents.changelog"/></span>*/}
                        {/*    </Link>*/}
                        {/*  </Menu.Item>*/}

                        {/*  <Menu.Item key="documents/installation">*/}
                        {/*    <Link to="/documents/installation">*/}
                        {/*      <i className="icon icon-steps"/>*/}
                        {/*      <span><IntlMessages id="sidebar.documents.installation"/></span>*/}
                        {/*    </Link>*/}
                        {/*  </Menu.Item>*/}

                        {/*</MenuItemGroup>*/}

                    </Menu>
                </CustomScrollbars>
            </div>
        </>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;

