import React, {useState, useEffect} from "react";
import { Form, Row, Col, Input, Button, Collapse ,Card, Table} from 'antd';
import jsonfile from "./dashboard.json";
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {CisUI} from "../../../util/CISUI";
import TotalRevenueCard from "../../../components/dashboard/CRM/TotalRevenueCard";
import NewCustomers from "../../../components/dashboard/CRM/NewCustomers";
import GrowthCard from "../../../components/dashboard/CRM/GrowthCard";
import IconWithTextCard from "../../../components/dashboard/CRM/IconWithTextCard";
import {recentActivity, taskList, trafficData} from "./data";
import IntlMessages from "../../../util/IntlMessages";

import CanvasJSReact from './../canvasjs/canvasjs.react';
import { useIntl } from "react-intl";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const FarmingDashboard = () => {

    const intl = useIntl();

    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.dashboard,
        reportTitle : 'Operational Dashboard',
        incomes : [],
        expenses : [],
        lastmonth: {
            income : 0,
            expense: 0,
            net: 0,
            mission: 0,
            others: 0,
            total: 0
        },
        currentmonth: {
            income : 0,
            expense: 0,
            net: 0,
            mission: 0,
            others: 0,
            total: 0
        },
        totalIncome : 0,
        totalExpense : 0,
        profit : 0
    });


    const IncomeExpenseOptions = {
        exportEnabled: true,
        animationEnabled: true,
        title:{
            text: intl.formatMessage({id: "Income & Expenses"}),
            fontColor : "#0a1151"
        },
        subtitles: [{
            text: "For "+state.localName,
            fontColor: "#b27d31",
        }],
        axisX: {
            title: "Day"
        },
        axisY: {
            title: intl.formatMessage({id: "Income"}),
            titleFontColor: "#0a1151",
            lineColor: "#0a1151",
            labelFontColor: "#0a1151",
            tickColor: "#0a1151",
            includeZero: true
        },
        axisY2: {
            title: intl.formatMessage({id: "Expenses"}),
            titleFontColor: "#b27d31",
            lineColor: "#b27d31",
            labelFontColor: "#b27d31",
            tickColor: "#b27d31",
            includeZero: true
        },
        toolTip: {
            shared: true
        },
        data: [{
            type: "column",
            name: intl.formatMessage({id: "Amount"}),
            color: "#0a1151",
            showInLegend: true,
            yValueFormatString: "$ #,##0.#",
            dataPoints: state.incomes
        },
            {
                type: "column",
                name: intl.formatMessage({id: "Expense"}),
                color: "#b27d31",
                showInLegend: true,
                yValueFormatString: "$ #,##0.#",
                dataPoints: state.expenses
            }]
    }

    useEffect(() => {
        getDashBoardData();
    }, []);

    const getDashBoardData = () => {
        const newURL = Config.apiserver+jsonfile.urls.dashboard;
        axios.get(newURL)
            .then((res) => {
                // console.log(res.data);
                document.getElementById("loader").style.display = "none";
                if(res.data.status === 1) {
                    //console.log("Response : "+ res.data);
                    const data = res.data;
                    console.log(data.incomeexpense);
                    setState({
                        incomes : data.incomes,
                        expenses : data.expenses,
                        totalIncome: data.totalIncome,
                        totalExpense: data.totalExpense,
                        profit: data.profit,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }


    return (
        <Row>
            {CisUI().showLoading}
            <Col span={24}>
                <div className="gx-card">
                    <div className="gx-card-body">
                        <Row>
                            <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-audi-col">
                               <h4><IntlMessages id="Summary"/></h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="gx-card" style={{background: '#0a1151', color : 'white'}}>
                                            <div className="gx-card-body">
                                                <h2 style={{textAlign: 'center', color : 'white'}}>{CisUI().getCurrencyFormated1(state.totalIncome)}</h2>
                                                <div style={{textAlign: 'center', color : '#ddd'}}><IntlMessages id="Total Income"/></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="gx-card" style={{background: '#b27d31', color : 'white'}}>
                                            <div className="gx-card-body">
                                                <h2 style={{textAlign: 'center', color : 'white'}}>{CisUI().getCurrencyFormated1(state.totalExpense)}</h2>
                                                <div style={{textAlign: 'center', color : '#ddd'}}><IntlMessages id="Total Expense"/></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="gx-card" style={{background: '#4ec274', color : 'white'}}>
                                            <div className="gx-card-body">
                                                <h2 style={{textAlign: 'center', color : 'white'}}>{CisUI().getCurrencyFormated1(state.profit)}</h2>
                                                <div style={{textAlign: 'center', color : '#ddd'}}><IntlMessages id="Profit"/></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col xl={18} lg={24} md={24} sm={24} xs={24} className="gx-visit-col">
                                <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
                                    <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4"><IntlMessages id="Income & Expenditure For current month"/></h6>
                                    <CanvasJSChart options = {IncomeExpenseOptions}
                                        /* onRef = {ref => this.chart = ref} */
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>

            <Row style={{display: 'none'}}>
                <Col xl={8} lg={24} md={8} sm={24} xs={24}>
                    <TotalRevenueCard/>
                </Col>
                <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                    <NewCustomers/>
                </Col>
                <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                    <GrowthCard trafficData={trafficData}/>
                </Col>

                <Col xl={16} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-1">
                    <Row>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard cardColor="cyan" icon="diamond" title="09" subTitle="Projects"/>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard cardColor="orange" icon="tasks" title="687" subTitle="Tasks"/>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard cardColor="teal" icon="team" title="04" subTitle="Teams"/>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <IconWithTextCard cardColor="red" icon="files" title="09" subTitle="Files"/>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Row>
    );
};

export default FarmingDashboard;
