import React from "react";
import {Route, Switch} from "react-router-dom";
import Main from "./main/index";
import Dashboard from "./main/index";
import User from "./user/index";
import Production from "./production/index";
import Flock from "./flock/index";
import Income from "./income/index";
import Expenses from "./expense/index";
import Reports from "./report/index";
import Settings from "./settings/index";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
    <div className="gx-main-content-wrapper">
        <Switch>
            <Route path={`${match.url}main`} component={Dashboard}/>
            <Route path={`${match.url}user`} component={User}/>
            <Route path={`${match.url}production`} component={Production}/>
            <Route path={`${match.url}flock`} component={Flock}/>
            <Route path={`${match.url}income`} component={Income}/>
            <Route path={`${match.url}expense`} component={Expenses}/>
            <Route path={`${match.url}reports`} component={Reports}/>
            <Route path={`${match.url}settings`} component={Settings}/>
        </Switch>
    </div>
);

export default App;

