import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import IntlMessages from "../../util/IntlMessages";
import axios from "axios";
import Config from "../../util/config";
import {CisUI} from "../../util/CISUI";

const UserProfile = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState("");

    const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>
          <Link to="/settings/profilesettings"><IntlMessages id="My Account"/></Link>
      </li>
      <li onClick={() => dispatch(userSignOut())}><IntlMessages id="Logout"/>
      </li>
    </ul>
  );
    const getUserData = (() => {
        axios.get(Config.apiserver + "myaccount", CisUI().HeaderRequest)
            .then(function (response) {
                console.log("response", response)
                if (response.data.status === 1) {
                    console.log("res", response.data.data)
                    setImage(response.data.data.image)
                    console.log("user", image)
                } else {
                    CisUI().Notification('error', response.data.msg);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    })
    useEffect(() => {
        getUserData()
    }, [])

  const userInfo = localStorage.getItem("userData");
  const user = JSON.parse(userInfo);

  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-2 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">

          { image !== "" ?
              <img src={`https://panttenapi.datalibrary.io/public/upload/profile/${image}`} alt="Image"
                   style={{width: "120px", height: "100px", marginTop: "10px"}} className="gx-size-40 gx-pointer gx-mr-3"/>
          :
            <Avatar src="/favicon.png" className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
          }

        <span className="gx-avatar-name">{user.name}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>

  )
};

export default UserProfile;
